import React from 'react';

// Styles
import './portal.scss';
import Button from '../../../common/Buttons/Button/Button';
import {BiDetail} from 'react-icons/bi';
import {FcHeatMap} from 'react-icons/fc';
import {AiOutlineNodeIndex} from 'react-icons/ai';
import {ImList} from 'react-icons/im';
import {HiOutlineFilter} from 'react-icons/hi';
import {getWorkflowsAlgorithms} from '../../../Header/selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {pageAlgorithmEnums} from '../../../Workflows/constants';
// Imgs
import WorkflowImg from '../../../../theme/assets/img/portal/ICONworkflow.png';
import {setActiveCategoriesAction} from '../../../Concept/ConceptSearchModal/store/actions';
import {PortalActions} from '../../enums';

const propTypes = {
  algorithms: PropTypes.instanceOf(Array),
  algorithmsEnumAvailable: PropTypes.instanceOf(Array),
  openConceptSearch: PropTypes.func,
  openConceptParamsSearch: PropTypes.func,
  openRankParamsDialog: PropTypes.func,
  setActiveCategories: PropTypes.func,
  setPortalAction: PropTypes.func,
};

const PortalPage = (props) => {
  const {
    algorithms,
    openConceptSearch,
    openConceptParamsSearch,
    openRankParamsDialog,
    setActiveCategories,
    setPortalAction,
  } = props;

  function openDiseaseSearch() {
    setPortalAction(PortalActions.MARKERS_IDENTIFY_DISEASE);
    setActiveCategories(['T902', 'T191']);
    openConceptSearch();
  }

  function openRankCandidatesDialog() {
    setPortalAction(PortalActions.RANK_CANDIDATES);
    setActiveCategories(['T902', 'T191']);
    openRankParamsDialog();
  }

  function openGeneSearch() {
    setPortalAction(PortalActions.TARGET_MARKER_ASSESSMENT);
    setActiveCategories(['T028']);
    openConceptSearch();
  }

  function openEnrichmentAnalysisSearch() {
    setPortalAction(PortalActions.ENRICHMENT_ANALYSIS);
    setActiveCategories(['T902']);
    openConceptParamsSearch();
  }

  const algorithmsEnumAvailable = algorithms.map((algo) => algo.name);

  return (
    <div className="portal-block">
      <div className="portal-block-rcorners portal-block-rcorners__workflows">
        <div className="portal-block__title">
          WORKFLOWS
        </div>
        <div className="portal-button-wrapper">
          <Button
            customClassName="button-secondary mr-5 portal-button"
            disabled={!algorithmsEnumAvailable.includes(pageAlgorithmEnums.singleTargetForCancer)}
            href={`/workflows?algorithm=${pageAlgorithmEnums.singleTargetForCancer}`}
          >
            <img src={WorkflowImg} className="icon" alt="Target identification in cancer" />
            Target identification in cancer
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            disabled={!algorithmsEnumAvailable.includes(pageAlgorithmEnums.biSpecificAntibody)}
            href={`/workflows?algorithm=${pageAlgorithmEnums.biSpecificAntibody}`}
          >
            <img src={WorkflowImg} className="icon" alt="Dual expressed cancer targets" />
            Dual expressed cancer targets
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            disabled={!algorithmsEnumAvailable.includes(pageAlgorithmEnums.singleCellStudyViewer)}
            href={`/workflows?algorithm=${pageAlgorithmEnums.singleCellStudyViewer}`}
          >
            <img src={WorkflowImg} className="icon" alt="Single cell study viewer" />
            Single cell study viewer
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            disabled={!algorithmsEnumAvailable.includes(pageAlgorithmEnums.cellLineSelection)}
            href={`/workflows?algorithm=${pageAlgorithmEnums.cellLineSelection}`}
          >
            <img src={WorkflowImg} className="icon" alt="Cancer cell line identifier" />
            Cancer cell line identifier
          </Button>
        </div>
      </div>

      <div className="portal-block-rcorners portal-block-rcorners__markers">
        <div className="portal-block__title">
          MARKERS & TARGETS
        </div>
        <div className="portal-button-wrapper">
          <Button
            customClassName="button-secondary mr-5 portal-button"
            onClick={openDiseaseSearch}
          >
            <HiOutlineFilter size={26} />
            Identify disease markers / targets
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            onClick={openRankCandidatesDialog}
          >
            <ImList className="rank-target-icon" />
            Rank targets for a disease
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            style={{ display: 'none' }}
            // disabled={this.setActionIsDisabled(setsActionsTypes.INTERSECT)}
            // onClick={goToSearchWithSetsPage}
          >
            <FcHeatMap size={26} className="heatmap-icon" />
            Single cell target expression
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            // style={{ display: 'none' }}
            onClick={openEnrichmentAnalysisSearch}
          >
            <AiOutlineNodeIndex size={26} />
            BioProfiler by Enrichment Analysis
          </Button>
          <Button
            customClassName="button-secondary mr-5 portal-button"
            onClick={openGeneSearch}
          >
            <BiDetail className="access-marker-icon" />
            Assess marker / target candidate
          </Button>
        </div>
      </div>
    </div>
  );
};

PortalPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    algorithms: getWorkflowsAlgorithms(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveCategories(data) {
      dispatch(setActiveCategoriesAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortalPage);
